import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import species_dummy from "../../assets/demo/flags/species_dummy.svg";
import { Chips } from "primereact/chips";

const CreateStateLaws = () => {
    const toast = useRef(null);
    const history = useHistory();




    const [stateLawInfo, setStateLawInfo] = useState({
        state:null,
        url:null
    });

    const [err, setErr] = useState({});



 const handleChange = ({name,value}) => {
    setStateLawInfo((prev)=>{
        return {
            ...prev,
            [name]:value
        }
    })

    setErr({ ...err, [name]: "" })
 }

    const validateForm = () => {
        let errors = {};
        if (!stateLawInfo.state) {
            errors.state = "State is required";
        }
        if (!stateLawInfo.url) {
            errors.url = "URL is required";
        }


        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            postData(Constants.END_POINT.CREATE_STATE_LAWS, stateLawInfo)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "State Law has been Created Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/state-laws"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };


console.log("stateLawInfo",stateLawInfo)

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add State Law</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="state" name="state" onChange={(e)=>handleChange({name:e.target.name,value:e.target.value})} value={stateLawInfo.state} />
                                <label htmlFor="state">State</label>
                            </span>
                            <div className="text-danger">{err.state}</div>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="url" name="url" onChange={(e)=>handleChange({name:e.target.name,value:e.target.value})} value={stateLawInfo.url} />
                                <label htmlFor="url">URL</label>
                            </span>
                            <div className="text-danger">{err.url}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add State Law
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateStateLaws;
