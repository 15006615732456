import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";

const CreateTrackingQuestions = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [trackingQuestions, setTrackingQuestions] = useState({
        questionTitle: "",
        questionType: "",
        option: "",
    });
    const [questionOptionIndex, setQuestionOptionIndex] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name==="questionType"){
            if(value==="DROPDOWN" || value==="LISTVIEW"){
                setQuestionOptionIndex(true)
            }
            else{
                setQuestionOptionIndex(false)
                setTrackingQuestions((prev)=>{
                    return {
                        ...prev,
                        option:""
                    }
                })
            }
        }
          
            setTrackingQuestions((prev)=>{
                return {
                    ...prev,
                    [name]:value
                }
            })
            
        setErr({ ...err, [name]: "" });
    };

    const trackerOption = [
        { label: "Text", value: "TEXT" },
        { label: "Drop Down", value: "DROPDOWN" },
        { label: "Multi Image", value: "MULTIIMAGE" },
        { label: "Single Image", value: "SINGLEIMAGE" },
    ];

    const validateForm = () => {
        let errors = {};
        if (!trackingQuestions.questionTitle) {
            errors.questionTitle = "Question Title is required";
        }
        if (!trackingQuestions.questionType) {
            errors.questionType = "Question Type is required";
        }


        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            postData(Constants.END_POINT.CREATE_TRACKING_QUESTION, trackingQuestions)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Hunting Question has been Created Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/tracking-questions"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Tracking Questions</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="questionTitle" name="questionTitle" onChange={handleChange} value={trackingQuestions.questionTitle} />
                                <label htmlFor="questionTitle">Question Title</label>
                            </span>
                            <div className="text-danger">{err.questionTitle}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown name="questionType" inputId="questionType" id="questionType" value={trackingQuestions?.questionType} options={trackerOption} onChange={handleChange} optionLabel="label" />
                                <label htmlFor="questionType">Field Type</label>
                            </span>
                            <div className="text-danger">{err.questionType}</div>
                        </div>
                        {questionOptionIndex && (
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    {/* <Dropdown name="option" inputId={item.id} id={item.id} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                    <Chips inputId="option" id="option" name="option" placeholder="Please press enter to add" value={trackingQuestions?.option} onChange={handleChange} separator="," />
                                    {/* <label htmlFor="option">option</label> */}
                                    <div className="text-danger">{err.option}</div>
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Tracking Question
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTrackingQuestions;
