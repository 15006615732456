import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../../assets/demo/flags/profile.png";
import { Chips } from "primereact/chips";
import species_dummy from "../../assets/demo/flags/species_dummy.svg";

const SpeciesEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();


 
   const [speciesImg,setSpeciesImg] = useState(null)
    const [speciesInfo, setSpeciesInfo] = useState({
        speciesName:"",
        speciesImage:""
    });

    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);


   

    const validateForm = () => {
        let errors = {};
        if (!speciesInfo) {
            errors.speciesName = "Species Name is required";
        }
        if (!speciesImg) {
            errors.speciesImage = "species Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    
const handleChange = (e) => {
const {name,value} = e.target;
setSpeciesInfo((prev)=>{
    return{
        ...prev,
        [name] : value
    }
})
setErr({ ...err, speciesName: "" })
    // (e)=>{setSpeciesInfo(e.target.value);setErr({ ...err, speciesName: "" });}
}

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("speciesName", speciesInfo.speciesName);
            if(speciesImg!==null){
                formData.append("speciesImage", speciesImg);
            }
            else{
                formData.append("speciesImage", speciesInfo.speciesImage);
            }
            
            putData1(Constants.END_POINT.UPDATE_SPECIES+id, formData)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "Species has been Upadted Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/species"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        getSingleSpecies();
    }, []);

    const getSingleSpecies = () => {
        getData(Constants.END_POINT.EDIT_SPECIES + id)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        speciesName:res.data.speciesName,
                        speciesImage:res.data.speciesImage
                    }
                    setSpeciesInfo(obj)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

console.log("speciesinfo",speciesInfo)
    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-center">
                        <h3>Edit Species</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={speciesImg ? URL.createObjectURL(speciesImg) : speciesInfo.speciesImage!=="" ? Constants.BASE_URL + speciesInfo.speciesImage  : species_dummy} alt="" className="weaponImageTag" />
                                    {speciesImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setSpeciesImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="speciesImage"
                                    name="speciesImage"
                                    onChange={(e) => {
                                        setSpeciesImg(e.target.files[0]);
                                        setErr({ ...err, speciesImage: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="speciesImage" className="weaponImageLabel">
                                    Edit Species Image
                                </label>
                                <div className="text-danger">{err.speciesImage}</div>
                            </div>
                            {/* <div className="text-danger">{err.speciesName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="speciesName" name="speciesName" onChange={handleChange} value={speciesInfo.speciesName} />
                                <label htmlFor="speciesName">Species Name</label>
                            </span>
                            <div className="text-danger">{err.speciesName}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Species
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeciesEdit;
