export default class Constants {

    // static BASE_URL = "https://fastrackapi.appdeft.biz/";
    static BASE_URL = "https://api.fastrakwhitetailrecovery.com/";  
    static END_POINT = {
        SIGIN: "api/admin/login",
        // CREATE_CATEGORY: "api/admin/create-category",
        // GET_ALL_CATEGORIES: "api/admin/categories",
        // ENABLE_DISABLE_CATEGORY: "api/admin/enable-disable-category/",
        USERS: "api/admin/users",
        USERS_VIEW:"api/admin/user/",
        DELETE_USER:"api/admin/user/",
        // BLOCK_UNBLOCK_USER: "api/admin/enable-disable-user/",
        GET_ADMIN_PROFILE: "api/admin/profile",
        UPDATE_ADMIN_PROFILE: "api/admin/update",
        CHANGE_PASSWORD: "api/admin/change-password",
        FORGOT_PASSWORD: "api/admin/forgot-password",
        RESET_PASSWORD: "api/admin/change-forgot-password",
        // UPDATE_CATEGORY: "api/admin/update-category/",
        UPLOAD_IMAGE: "api/admin/upload-image",

        CREATE_WEAPON: "api/admin/create-Weapon",
        UPDATE_WEAPON:"api/admin/update-weapon/",
        DELETE_WEAPON:"api/admin/delete-weapon/",
        WEAPONS: "api/admin/weapons/",
        EDIT_WEAPONS: "api/admin/weapon/",
        ALL_TRACKER_QUESTIONS: "api/admin/all-tracker-question",
        CREATE_TRACKER_QUESTION: "api/admin/create-tracker-question",
        DELETE_TRACKER_QUESTION: "api/admin/delete-tracker-question/",
        UPDATE_TRACKER_QUESTION:"api/admin/update-tracker-question/",
        EDIT_TRACKER_QUESTION:"api/admin/tracker-question/",
        SPECIES: "api/admin/all-species/",
        CREATE_SPECIES: "api/admin/create-species/",
        DELETE_SPECIES: "api/admin/delete-species/",
        EDIT_SPECIES:"api/admin/species/",
        UPDATE_SPECIES:"api/admin/update-species/",
        POINT_MARKER:"api/admin/all-point-marker/",
        DELETE_POINT_MARKER:"api/admin/delete-point-marker/",
        CREATE_POINT_MARKER:"api/admin/create-point-marker/",
        EDIT_POINT_MARKER:"api/admin/point-marker/",
        UPDATE_POINT_MARKER:"api/admin/update-point-marker/",
        ALL_HUNTING_QUESTION:"api/admin/all-hunting-question/",
        CREATE_HUNTING_QUESTION:"api/admin/create-hunting-question",
        DELETE_HUNTING_QUESTION:"api/admin/delete-hunting-question/",
        SINGLE_QUESTION:"api/admin/hunting-question/",
        UPDATE_HUNTING_QUESTION:"api/admin/update-hunting-question/",
        ALL_TRACKING_QUESTIONS:"api/admin/all-tracking-question/",
        DELETE_TRACKING_QUESTION:"api/admin/delete-tracking-question/",
        CREATE_TRACKING_QUESTION:"api/admin/create-tracking-question/",
        UPDATE_TRACKING_QUESTION:"api/admin/update-tracking-question/",
        EDIT_TRACKING_QUESTIONS:"api/admin/tracking-question/",
        ALL_TARGET_POINT:"api/admin/all-target-point",
        DELETE_TARGET_POINT:"api/admin/delete-target-point/",
        CREATE_TARGET_POINT:"api/admin/create-target-point/",
        EDIT_TARGET_POINT:"api/admin/target-point/",
        UPDATE_TARGET_POINT:"api/admin/update-target-point/",
        GET_ALL_BODY_PARTS:"api/admin/body-parts/",
        DELETE_BODY_PART:"api/admin/delete-body-part/",
        CREATE_BODY_PART:"api/admin/create-body-part/",
        UPDATE_BODY_PART:"api/admin/update-body-part/",
        EDIT_BODY_PART:"api/admin/body-part/",
        GET_ALL_COMMENTS:"api/admin/all-comment-report/",
        VIEW_COMMENT:"api/admin/get-comment-reports/",
        GET_ALL_POSTS:"api/admin/all-post-report/",
        VIEW_POST:"api/admin/get-post-report/",
        GET_ALL_STATE_LAWS:"api/admin/state-lows",
        DELETE_STATE_LAWS:"api/admin/delete-state-low/",
        CREATE_STATE_LAWS:"api/admin/create-state-low",
        SINGLE_STATE_LAWS:"api/admin/state-low/",
        UPDATE_STATE_LAWS:"api/admin/update-state-low/",
        CREATE_API_KEY:"api/admin/create-api-key",
        ALL_API_KEY:"api/admin/api-keys",
        UPDATE_API_KEY:"api/admin/update-api-key/",
        DELETE_API_KEY:"api/admin/delete-api-key/",
        // Providers

        
        // BLOCK_UNBLOCK_PROVIDER: "api/admin/enable-disable-provider/",
        // GET_PROVIDER: "api/admin/provider/",
        // VERIFY_BUSINESS_PROVIDER: "api/admin/verify-business/",

        // Amenities
        // GET_ALL_AMENITIES: "api/admin/amenities",
        // CREATE_AMENITIES: "api/admin/create-amenities",
        // UPDATE_AMINITIES: "api/admin/update-amenity/",
        // DELETE_AMENITIES: "api/admin/delete-amenities/",

        //safety Rules
        // GET_ALL_SAFETY_RULES: "api/admin/safety-rules",
        // CREATE_SAFETY_RULES: "api/admin/create-safety-rules",
        // UPDATE_SAFETY_RULES: "api/admin/update-safety-rules/",
        // DELETE_SAFETY_RULES: "api/admin/delete-safety-rules/",

        // GET_ALL_BUSINESS_REPORTS: "api/admin/get-all-business-reports",
        // GET_ALL_PROVIDER_FEEDBACK: "api/admin/feedbacks",
        // GET_ALL_USERS: "api/admin/users",

        // GET_USER_BOOKINGS: "api/admin/user/bookings/",
        // GET_PROVIDER_BOOKINGS: "api/admin/provider/bookings/",
        GET_COUNT: "api/admin/count",
        ALL_BOOKINGS: "api/admin/all-bookings",

        // PAYMENTS: "api/admin/my-earnings",
    };
}
