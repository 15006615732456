import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../assets/demo/flags/profile.png";

import { Chips } from "primereact/chips";
import { MultiSelect } from "primereact/multiselect";

const UserView = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [userImg, setUserImg] = useState(null);
    const [userView, setUserView] = useState({
        firstName: "",
        lastName: "",
        image: "",
        email: "",
        countryCode: "",
        mobile: "",
        isVerified: "",
    });

    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);

    console.log("err", err);
    console.log("userView", userView);

    useEffect(() => {
        getSingleUser();
    }, []);

    const getSingleUser = () => {
        getData(Constants.END_POINT.USERS_VIEW + id)
            .then((res) => {
                if (res.success) {
                    // console.log("resss",res.data)
                    let obj = {
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        image: res.data.image,
                        email: res.data.email,
                        countryCode: res.data.countryCode,
                        mobile: res.data.mobile,
                        role: res.data.role,
                    };
                    setUserView(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    console.log("userViewoo", userView);

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    {loading ? (
                        <div className="overlay-loading">
                            <div class="spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null}
                    <div className=" flex justify-content-center">
                        <h3>User Information</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={userImg ? URL.createObjectURL(userImg) : userView.image ? Constants.BASE_URL + userView.image : profile} alt="" className="weaponImageTag" />
                                    {userImg !== null ? (
                                        <div className="weapon-edit-div">
                                            <i onClick={() => setUserImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                        </div>
                                    ) : null}
                                </div>

                                {/* <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={(e) => {
                                        setUserImg(e.target.files[0]);
                                        setErr({ ...err, image: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="image" className="weaponImageLabel">
                                    Update User Image
                                </label> */}
                                {/* <div className="text-danger">{err.image}</div> */}
                            </div>
                            {/* <div className="text-danger">{err.firstName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="firstName" name="firstName" value={userView.firstName} style={{opacity:1}} disabled/>
                                <label htmlFor="firstName">First Name</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="lastName" name="lastName" value={userView.lastName} style={{opacity:1}} disabled/>
                                <label htmlFor="lastName">Last Name</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="email" name="email" value={userView.email} style={{opacity:1}} disabled/>
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="countryCode" name="countryCode" value={userView.countryCode} style={{opacity:1}} disabled/>
                                <label htmlFor="countryCode">Country Code</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="mobile" name="mobile" value={userView.mobile} style={{opacity:1}} disabled/>
                                <label htmlFor="mobile">Phone Number</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="role" name="role" value={userView.role} style={{opacity:1}} disabled/>
                                <label htmlFor="role">Role</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserView;
