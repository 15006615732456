import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { deleteData, getData, postData, postData1, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import species_dummy from "../../assets/demo/flags/species_dummy.svg";
import { Chips } from "primereact/chips";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const CreateAPIKey = () => {
    const toast = useRef(null);
    const history = useHistory();



    const [loading, setLoading] = useState(true);
    const [apiKey, setAPIKey] = useState({
        apiKey:null
    });

    const [err, setErr] = useState({});



 const handleChange = ({name,value}) => {
    setAPIKey((prev)=>{
        return {
            ...prev,
            [name]:value
        }
    })

    setErr({ ...err, [name]: "" })
 }

    const validateForm = () => {
        let errors = {};
        if (!apiKey.apiKey) {
            errors.apiKey = "API Key is required";
        }


        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const deleteConfirm = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName:'cancel-button',
            accept:()=>acceptFunc(id),
            reject
        });
    };

    const acceptFunc = (id) => {
        deleteData(Constants.END_POINT.DELETE_API_KEY+id)
        .then((res)=>{
            if(res.success){
                toast.current.show({ severity: "info", summary: "", detail: "API Key Deleted Succesfully", life: 3000 });
                getAPIKey();
            }
        })
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            if(apiKey._id){
                putData(Constants.END_POINT.UPDATE_API_KEY+apiKey._id, apiKey)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "API Key has been Updated Succesfully", life: 3000 });
                                    
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
            else{
                postData(Constants.END_POINT.CREATE_API_KEY, apiKey)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "API Key has been Created Succesfully", life: 3000 });
                                    
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
           
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        getAPIKey();
    }, []);

    const getAPIKey = () => {
        getData(Constants.END_POINT.ALL_API_KEY)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        ...res.data[0],
                        apiKey:res.data[0].apiKey,
                    }
                    setAPIKey(obj)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-center">
                        <h3>API Key</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="apiKey" name="apiKey" onChange={(e)=>handleChange({name:e.target.name,value:e.target.value})} value={apiKey.apiKey} />
                                <label htmlFor="apiKey">API Key</label>
                            </span>
                            <div className="text-danger">{err.apiKey}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Save
                            </Button>
                            {
                                apiKey._id ? 
                                <Button type="button" className="p-button-md p-button-danger ml-2" onClick={()=>deleteConfirm(apiKey._id)}>
                            Delete
                        </Button>
                        : null
                            }
                            
                            
                            
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateAPIKey;
