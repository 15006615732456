import React from "react";
import logo from "../src/assets/images/fastrack_logo.png"

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <img src={logo} alt="Logo" className="mr-2" />

            {/* <span className="font-medium">
                {" "}
                INSTA<span style={{ color: "#ffdb4d" }}>JAMAICA</span>
            </span> */}
        </div>
    );
};
