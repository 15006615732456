import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import point_marker_dummy from "../../assets/demo/flags/point-marker-dummy.svg";
import { Chips } from "primereact/chips";

const CreatePointerMarker = () => {
    const toast = useRef(null);
    const history = useHistory();



 
   const [pointMarkerImg,setPointMarkerImg] = useState(null)
    const [PointMarkerInfo, setPointMarkerInfo] = useState({
        markerName:"",
        type:'',
    });

    const [err, setErr] = useState({});

    console.log("PointMarkerInfo",PointMarkerInfo)

const PointTypes = [
    {
    name:'Way Point',
    value:'WAYPOINT'
},
{
    name:'Hit Point',
    value:'HITPOINT'
},
]

    console.log("err", err);

    const onChange = (e) => {
        const {name,value} = e.target
        setPointMarkerInfo((prev)=>{
            return {
                ...prev,
                [name]: value
            }
        })
        setErr({ ...err, [name]: "" })
    }

    const validateForm = () => {
        let errors = {};
        if (!PointMarkerInfo.markerName) {
            errors.markerName = "Point Marker Name is required";
        }
        if (!PointMarkerInfo.type) {
            errors.type = "Point Marker Type is required";
        }
        if (!pointMarkerImg) {
            errors.pointMarkerImage = "Point Marker Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("markerImage", pointMarkerImg);
            formData.append("markerName", PointMarkerInfo.markerName);
            formData.append("type", PointMarkerInfo.type);
            postData1(Constants.END_POINT.CREATE_POINT_MARKER, formData)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "Point Marker has been Created Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/point-marker"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };




    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Point Marker</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={pointMarkerImg ? URL.createObjectURL(pointMarkerImg) : point_marker_dummy} alt="" className="weaponImageTag" />
                                    {pointMarkerImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setPointMarkerImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="pointMarkerImage"
                                    name="pointMarkerImage"
                                    onChange={(e) => {
                                        setPointMarkerImg(e.target.files[0]);
                                        setErr({ ...err, pointMarkerImage: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="pointMarkerImage" className="weaponImageLabel">
                                    Add Point Marker Image
                                </label>
                                <div className="text-danger">{err.pointMarkerImage}</div>
                            </div>
                            {/* <div className="text-danger">{err.speciesName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="markerName" name="markerName" onChange={onChange} value={PointMarkerInfo.markerName} />
                                <label htmlFor="markerName">Point Marker Name</label>
                            </span>
                            <div className="text-danger">{err.markerName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                            <Dropdown id="type" name="type" onChange={onChange} value={PointMarkerInfo.type} options={PointTypes} optionLabel="name" className="w-full" />
                                {/* <InputText id="type" name="type" onChange={onChange} value={PointMarkerInfo.type} /> */}
                                <label htmlFor="type">Type</label>
                            </span>
                            <div className="text-danger">{err.type}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Point Marker
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePointerMarker;
