import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const TrackingQuestions = () => {
    const toast = useRef(null);

    const [trackingQuestions,setTrackingQuestions] = useState([])
    const [loading, setLoading] = useState(true);

    const deleteTrackingQuestions = (id) => {
        deleteData(Constants.END_POINT.DELETE_TRACKING_QUESTION+id)
        .then((res)=>{
            if(res.success){
                toast.current.show({ severity: "info", summary: "", detail: "Tracking Question Deleted Succesfully", life: 3000 });
                getAllTrackingQuestion();
            }
        })
    }

    const getAllTrackingQuestion = () => {
        getData(Constants.END_POINT.ALL_TRACKING_QUESTIONS)
            .then((res) => {
                if (res.success) {
                    setTrackingQuestions(res.data);
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const acceptFunc = (id) => {
        deleteTrackingQuestions(id)
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const deleteConfirm = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName:'cancel-button',
            accept:()=>acceptFunc(id),
            reject
        });
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/tracking-questions-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button
                    type="button"
                    className="p-button-danger "
                    onClick={()=>deleteConfirm(rowData?._id)}
                >
                    Delete
                </Button>   
            </div>
        );
    };

    useEffect(() => {
        getAllTrackingQuestion();
    }, []);
  return (
    <div className="col-12">
    <div className="card">
    <div className="flex justify-content-between mb-4">
    <Toast ref={toast} />
                <h5>Tracking Questions</h5>
                <div className="flex justify-content-end mr-3">
                    <Link to="/create-tracking-questions">
                        <Button className="p-button-info" icon="pi pi-plus" label="Create Tracking Question" />
                    </Link>
                    {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                </div>
            </div>
        <DataTable value={trackingQuestions} paginator rows={10} loading={loading}>
            <Column field="questionTitle"  header="Question Title" sortable  />
            {/* <Column field="business.businessName" header="Business Name" sortable style={{ width: "30px" }} /> */}
            {/* <Column field="placeholder"  header="Placeholder" sortable  /> */}
            <Column field="questionType"  header="Question Type" sortable  />
            {/* <Column field="business.isVerified" header="Status" sortable body={statusBodyTemplate2} style={{ width: "30px" }} />
            <Column field="time" header="Created On" sortable body={(d) => formatDate(new Date(d.time))} style={{ width: "30px" }} /> */}
            <Column field="block" header="Action" body={ActionBodyTemplate} style={{width:'10px'}} />
        </DataTable>
    </div>
</div>
  )
}

export default TrackingQuestions