import Dashboard from "../components/Dashboard";
// import FormLayoutDemo from "../components/FormLayoutDemo";
// import Categories from "../components/Categories";
import Users from "../components/Users";
import Weapons from "../components/weapons/Weapons";
import Profile from "../pages/Profile";
// import ProviderInfo from "../pages/ProviderInfo";
// import Amenities from "../components/Amenities";
// import SafetyRules from "../components/SafetyRules";
// import Reports from "../components/Reports";

import CreateWeapon from "../components/weapons/CreateWeapon";
import WeaponEdit from "../components/weapons/WeaponEdit";
import TrackerQuestions from "../components/TrackerQuestions/TrackerQuestions";
import CreateTrackerQuestions from "../components/TrackerQuestions/CreateTrackerQuestions";
import TrackerQuestionEdit from "../components/TrackerQuestions/TrackerQuestionEdit";
import Species from "../components/species/Species";
import CreateSpecies from "../components/species/CreateSpecies";
import SpeciesEdit from "../components/species/SpeciesEdit";
import PointMarker from "../components/PointMarker.js/PointMarker";
import CreatePointerMarker from "../components/PointMarker.js/CreatePointMarker";
import PointMarkerEdit from "../components/PointMarker.js/PointMarkerEdit";
import HuntingQuestions from "../components/HuntingQuestions/HuntingQuestions";
import CreateHuntingQuestions from "../components/HuntingQuestions/CreateHuntingQuestions";
import HuntingQuestionEdit from "../components/HuntingQuestions/HuntingQuestionEdit";
import TrackingQuestions from "../components/TrackingQuestions/TackingQuestions";
import CreateTrackingQuestions from "../components/TrackingQuestions/CreateTrackingQuestions";
import TrackingQuestionEdit from "../components/TrackingQuestions/TrackingQuestionEdit";
import TargetPoint from "../components/TargetPoint/TargetPoint";
import CreateTargetPoint from "../components/TargetPoint/CreateTargetPoint";
import TargetPointEdit from "../components/TargetPoint/TargetPointEdit";
import BodyParts from "../components/BodyParts/BodyParts";
import CreateBodyPart from "../components/BodyParts/CreateBodyPart";
import BodyPartEdit from "../components/BodyParts/BodyPartEdit";
import Comments from "../components/Reports/Comments";
import ViewComment from "../components/Reports/ViewComment";
import Posts from "../components/Reports/Posts";
import ViewPost from "../components/Reports/ViewPost";
import UserView from "../components/UserView";
import StateLaws from "../components/StateLaws/StateLaws";
import CreateStateLaws from "../components/StateLaws/CreateStateLaws";
import StateLawsEdit from "../components/StateLaws/StateLawsEdit";
import CreateAPIKey from "../components/WeatherApiKey/CreateAPIKey";

export const PrivateRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    // {
    //     path: "/payments",
    //     name: "Payments",
    //     component: Payments,
    // },
    // {
    //     path: "/categories",
    //     name: "Categories",
    //     component: Categories,
    // },
    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/user-view/:id",
        name: "UserView",
        component: UserView,
    },
    {
        path: "/weapons",
        name: "Weapons",
        component: Weapons,
    },
    {
        path: "/create-weapon/",
        name: "CreateWeapon",
        component: CreateWeapon,
    },
    {
        path: "/weapon-edit/:id",
        name: "WeaponEdit",
        component: WeaponEdit,
    },
    {
        path: "/tracker-questions",
        name: "TrackerQuestions",
        component: TrackerQuestions,
    },
    {
        path: "/create-tracker-questions/",
        name: "CreateTrackerQuestions",
        component: CreateTrackerQuestions,
    },
    {
        path: "/tracker-questions-edit/:id",
        name: "TrackerQuestionEdit",
        component: TrackerQuestionEdit,
    },

    {
        path: "/species",
        name: "Species",
        component: Species,
    },
    {
        path: "/create-species/",
        name: "CreateSpecies",
        component: CreateSpecies,
    },
    {
        path: "/species-edit/:id",
        name: "SpeciesEdit",
        component: SpeciesEdit,
    },
    {
        path: "/point-marker",
        name: "PointMarker",
        component: PointMarker,
    },
    {
        path: "/create-point-marker/",
        name: "CreatePointerMarker",
        component: CreatePointerMarker,
    },
    {
        path: "/point-marker-edit/:id",
        name: "PointMarkerEdit",
        component: PointMarkerEdit,
    },
    {
        path: "/hunting-questions",
        name: "HuntingQuestions",
        component: HuntingQuestions,
    },
    {
        path: "/create-hunting-questions/",
        name: "CreateHuntingQuestions",
        component: CreateHuntingQuestions,
    },
    {
        path: "/hunting-questions-edit/:id",
        name: "HuntingQuestionEdit",
        component: HuntingQuestionEdit,
    },

    {
        path: "/tracking-questions",
        name: "TrackingQuestions",
        component: TrackingQuestions,
    },
    {
        path: "/create-tracking-questions/",
        name: "CreateTrackingQuestions",
        component: CreateTrackingQuestions,
    },
    {
        path: "/tracking-questions-edit/:id",
        name: "TrackingQuestionEdit",
        component: TrackingQuestionEdit,
    },

    {
        path: "/target-point",
        name: "TargetPoint",
        component: TargetPoint,
    },
    {
        path: "/create-target-point/",
        name: "CreateTargetPoint",
        component: CreateTargetPoint,
    },
    {
        path: "/target-point-edit/:id",
        name: "TargetPointEdit",
        component: TargetPointEdit,
    },
    {
        path: "/body-part",
        name: "BodyParts",
        component: BodyParts,
    },
    {
        path: "/create-body-part/",
        name: "CreateBodyPart",
        component: CreateBodyPart,
    },
    {
        path: "/body-part-edit/:id",
        name: "BodyPartEdit",
        component: BodyPartEdit,
    },

    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },

    {
        path: "/report-comments",
        name: "Comments",
        component: Comments,
    },
    {
        path: "/view-comment/:id",
        name: "ViewComment",
        component: ViewComment,
    },

    {
        path: "/report-posts",
        name: "Posts",
        component: Posts,
    },
    {
        path: "/view-post/:id",
        name: "ViewPost",
        component: ViewPost,
    },
    {
        path: "/state-laws",
        name: "StateLaws",
        component: StateLaws,
    },
    {
        path: "/create-state-laws/",
        name: "CreateStateLaws",
        component: CreateStateLaws,
    },
    {
        path: "/state-laws-edit/:id",
        name: "StateLawsEdit",
        component: StateLawsEdit,
    },
    {
        path: "/api-key",
        name: "CreateAPIKey",
        component: CreateAPIKey,
    },
    // {
    //     path: "/providerInfo/:id",
    //     name: "ProviderInfo",
    //     component: ProviderInfo,
    // },

    // {
    //     path: "/formlayout",
    //     name: "FormLayout",
    //     component: FormLayoutDemo,
    // },
    // {
    //     path: "/amenities",
    //     name: "Amenities",
    //     component: Amenities,
    // },
    // {
    //     path: "/safety-rules",
    //     name: "SafetyRules",
    //     component: SafetyRules,
    // },
    // {
    //     path: "/reports",
    //     name: "Reports",
    //     component: Reports,
    // },
    // {
    //     path: "/feedback",
    //     name: "FeedBack",
    //     component: Feedback,
    // },
    // {
    //     path: "/bookingDetail/:id",
    //     name: "BookingDetail",
    //     component: BookingDetails,
    // },
];
