import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
// import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";
import { formatDate } from "../../utils/dateConversions";
import dummy from "../../assets/images/dummy1.png"
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const PointMarker = () => {
    const toast = useRef(null);
    useEffect(() => {
        getAllPointMarker();
    }, []);
    const getAllPointMarker = () => {
        getData(Constants.END_POINT.POINT_MARKER)
            .then((res) => {
                if (res.success) {
                    setPointMarker(res.data);
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deletePointMarker = (id) => {
        deleteData(Constants.END_POINT.DELETE_POINT_MARKER+id)
        .then((res)=>{
            if(res.success){
                toast.current.show({ severity: "info", summary: "", detail: "Point Marker Deleted Succesfully", life: 3000 });
                getAllPointMarker();
            }
        })
    }

    const [pointMarker, setPointMarker] = useState();
    const [loading, setLoading] = useState(true);


    const pointMarkerImageTemplate = (rowData) => {
        return <img src={rowData?.markerImage ? Constants.BASE_URL+rowData?.markerImage :dummy} style={{width:"70px",height:"70px"}}/>
    }


    const acceptFunc = (id) => {
        deletePointMarker(id)
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const deleteConfirm = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName:'cancel-button',
            accept:()=>acceptFunc(id),
            reject
        });
    };


    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/point-marker-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button
                    type="button"
                    className="p-button-danger "
                    onClick={()=>deleteConfirm(rowData?._id)}
                >
                    Delete
                </Button>   
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
            <div className="flex justify-content-between mb-4">
            <Toast ref={toast} />
                        <h5>Point Marker</h5>
                        <div className="flex justify-content-end mr-3">
                            <Link to="/create-point-marker">
                                <Button className="p-button-info" icon="pi pi-plus" label="Create Point Marker" />
                            </Link>
                            {/* <Button label="Export" icon="pi pi-upload" className="p-button-help ml-3" onClick={() => ExportCSV(exportData)} /> */}
                        </div>
                    </div>
                <DataTable value={pointMarker} paginator rows={10} loading={loading}>
                    <Column field="markerName"  header="Point Marker Name" sortable  />
                    <Column field="markerImage" header="Point Marker Image" sortable  body={pointMarkerImageTemplate} />
                    <Column field="type"  header="Type" sortable  />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{width:'10px'}} />
                </DataTable>
            </div>
        </div>
    );
};

export default PointMarker;
