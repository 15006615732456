import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import ProtectedRoute from "./Routes";
// import ButtonDemo from "./components/ButtonDemo";
// import ChartDemo from "./components/ChartDemo";
// import Documentation from "./components/Documentation";
// import FileDemo from "./components/FileDemo";
// import FloatLabelDemo from "./components/FloatLabelDemo";
// import InputDemo from "./components/InputDemo";
// import ListDemo from "./components/ListDemo";
// import MenuDemo from "./components/MenuDemo";
// import MessagesDemo from "./components/MessagesDemo";
// import MiscDemo from "./components/MiscDemo";
// import OverlayDemo from "./components/OverlayDemo";
// import MediaDemo from "./components/MediaDemo";
// import PanelDemo from "./components/PanelDemo";
// import TableDemo from "./components/TableDemo";
// import TreeDemo from "./components/TreeDemo";
// import InvalidStateDemo from "./components/InvalidStateDemo";
// import BlocksDemo from "./components/BlocksDemo";
// import IconsDemo from "./components/IconsDemo";

// import Crud from "./pages/Crud";
// import EmptyPage from "./pages/EmptyPage";
// import TimelineDemo from "./pages/TimelineDemo";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./assets/layout/sass/custom.css"
import "./App.scss";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                },
                // { label: "Payments", icon: "pi pi-fw pi-money-bill", to: "/payments" },
                // { label: "Categories", icon: "pi pi-fw pi-book", to: "/categories" },
                { label: "Users", icon: "pi pi-fw pi-users", to: "/users" },
                { label: "Hunter", 
                icon: "pi pi-fw pi-prime", 
                items: [
                    { label: "Weapons", icon: "pi pi-fw pi-bolt", to: "/weapons" },
                    { label: "Species", icon: "pi pi-fw pi-discord", to: "/species" },
                    { label: "Way Point", icon: "pi pi-fw pi-map-marker", to: "/point-marker" },
                    { label: "Target Point", icon: "pi pi-fw pi-sign-in", to: "/target-point" },
                    { label: "Body Part", icon: "pi pi-fw pi-eye", to: "/body-part" },
                    { label: "Hunting Questions", icon: "pi pi-fw pi-question", to: "/hunting-questions" },
                ]
            },
            { label: "Tracker", 
                icon: "pi pi-fw pi-map", 
                items: [
                    { label: "Tracker Profile Questions", icon: "pi pi-fw pi-question-circle", to: "/tracker-questions" },
                    { label: "Tracking Questions", icon: "pi pi-fw pi-question-circle", to: "/tracking-questions" },
                    
                ]
            },
            { label: "Reports", 
            icon: "pi pi-fw pi-briefcase",
            items: [
                { label: "Comments", icon: "pi pi-fw pi-comment", to: "/report-comments" },
                { label: "Posts", icon: "pi pi-fw pi-book", to: "/report-posts" },
                
            ]  
        },
        { label: "State Laws", icon: "pi pi-fw pi-server", to: "/state-laws" },
        { label: "Weather API Key", icon: "pi pi-fw pi-key", to: "/api-key" },
                

                // { label: "Providers/Business", icon: "pi pi-fw pi-briefcase", to: "/providers" },
                // { label: "Amenities", icon: "pi pi-fw pi-fw pi-bookmark", to: "/amenities" },
                // { label: "Safety Rules", icon: "pi pi-fw pi-id-card", to: "/safety-rules" },
                // { label: "Reports", icon: "pi pi-fw pi-chart-bar", to: "/reports" },
                // { label: "FeedBack", icon: "pi pi-fw pi-check-square", to: "/feedback" },
            ],
        },
        // {
        //     label: "UI Components",
        //     icon: "pi pi-fw pi-sitemap",
        //     items: [
        //         { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        //         { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        //         { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //         { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //         { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //         { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //         { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //         { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
        //         { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //         { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //         { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //         { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //         { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //     ],
        // },
        // {
        //     label: "UI Blocks",
        //     items: [
        //         { label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW" },
        //         { label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-react" },
        //     ],
        // },
        // {
        //     label: "Icons",
        //     items: [{ label: "PrimeIcons", icon: "pi pi-fw pi-prime", to: "/icons" }],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: "Menu Hierarchy",
        //     icon: "pi pi-fw pi-search",
        //     items: [
        //         {
        //             label: "Submenu 1",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 1.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 1.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             label: "Submenu 2",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 2.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 2.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     label: "Get Started",
        //     items: [
        //         {
        //             label: "Documentation",
        //             icon: "pi pi-fw pi-question",
        //             command: () => {
        //                 window.location = "#/documentation";
        //             },
        //         },
        //         {
        //             label: "View Source",
        //             icon: "pi pi-fw pi-search",
        //             command: () => {
        //                 window.location = "https://github.com/primefaces/sakai-react";
        //             },
        //         },
        //     ],
        // },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <ProtectedRoute />
                    {/* <Route path="*" name="Dashboard" element={<ProtectedRoute />} /> */}
                    {/* <Route path="/dashboard" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                    {/* {<Route path="/formlayout" component={FormLayoutDemo} />} */}


                    {/* <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/media" component={MediaDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/blocks" component={BlocksDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" render={() => <ChartDemo colorMode={layoutColorMode} location={location} />} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/documentation" component={Documentation} /> */}
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
