import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { MultiSelect } from 'primereact/multiselect';

const CreateHuntingQuestions = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [huntingQuestions, setHuntingQuestions] = useState({
        questionTitle: "",
        questionType: "",
        typeofWeapon:"",
        option: [],
        viewOption:[],
    });
    const [questionOptionIndex, setQuestionOptionIndex] = useState(false);
    const[questionListView,setQuestionListView] = useState(false)

    console.log("huntingQuestions",huntingQuestions)

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name==="questionType"){
            if(value==="DROPDOWN" ){
                setQuestionOptionIndex(true)
                setQuestionListView(false)
                setHuntingQuestions((prev)=>{
                    return {
                        ...prev,
                        viewOption:[],
                    }
                })
            }
            else if(value==="LISTVIEW"){
                setQuestionListView(true)
                setQuestionOptionIndex(false)
                setHuntingQuestions((prev)=>{
                    return {
                        ...prev,
                        option:[],
                    }
                })
            }
            else{
                setQuestionOptionIndex(false)
                setQuestionListView(false)
                setHuntingQuestions((prev)=>{
                    return {
                        ...prev,
                        option:[],
                        viewOption:[]
                    }
                })
            }
        }
          
            setHuntingQuestions((prev)=>{
                return {
                    ...prev,
                    [name]:value
                }
            })
            
        setErr({ ...err, [name]: "" });
    };

    const trackerOption = [
        { label: "Text", value: "TEXT" },
        { label: "Drop Down", value: "DROPDOWN" },
        { label: "List View", value: "LISTVIEW" },
        { label: "Date Time", value: "DATETIME" },
        { label: "Predefined", value: "PREDEFINED" },
        { label: "TextArea", value: "TEXTAREA" },
    ];

    const weaponTypeOption = [
        { label: "Cross Bow", value: "CROSSBOW" },
        { label: "Compound Bow", value: "COMPOUNDBOW" },
        { label: "Recurve", value: "RECURVE" },
        { label: "Rifle", value: "RIFLE" },
        { label: "Shotgun", value: "SHOTGUN" },
        { label: "Muzzleloader", value: "MUZZLELOADER" },
        { label: "Handgun", value: "HANDGUN" },
    ];

    const validateForm = () => {
        let errors = {};
        if (!huntingQuestions.questionTitle) {
            errors.questionTitle = "Question Title is required";
        }
        if (!huntingQuestions.questionType) {
            errors.questionType = "Question Type is required";
        }
        if (!huntingQuestions.typeofWeapon) {
            errors.typeofWeapon = "Weapons Type is required";
        }


        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            let paylaod =  {
                ...huntingQuestions,
                typeofWeapon: huntingQuestions?.typeofWeapon?.length === weaponTypeOption?.length ? ["COMMON"] : huntingQuestions?.typeofWeapon
            }
            postData(Constants.END_POINT.CREATE_HUNTING_QUESTION, paylaod)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Hunting Question has been Created Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/hunting-questions"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Hunting Questions</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <InputText id="questionTitle" name="questionTitle" onChange={handleChange} value={huntingQuestions.questionTitle} />
                                <label htmlFor="questionTitle">Question Title</label>
                            </span>
                            <div className="text-danger">{err.questionTitle}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown name="questionType" inputId="questionType" id="questionType" value={huntingQuestions?.questionType} options={trackerOption} onChange={handleChange} optionLabel="label" />
                                <label htmlFor="questionType">Field Type</label>
                            </span>
                            <div className="text-danger">{err.questionType}</div>
                        </div>
                        {questionOptionIndex && (
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    {/* <Dropdown name="option" inputId={item.id} id={item.id} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                    <Chips inputId="option" id="option" name="option" placeholder="Please press enter to add" value={huntingQuestions?.option} onChange={handleChange} separator="," />
                                    {/* <label htmlFor="option">option</label> */}
                                    <div className="text-danger">{err.option}</div>
                                </span>
                            </div>
                        )}

{questionListView && (
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    {/* <Dropdown name="option" inputId={item.id} id={item.id} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                    <Chips inputId="viewOption" id="viewOption" name="viewOption" placeholder="Please press enter to add to List" value={huntingQuestions?.viewOption} onChange={handleChange} separator="," />
                                    {/* <label htmlFor="viewOption">viewOption</label> */}
                                    <div className="text-danger">{err.viewOption}</div>
                                </span>
                            </div>
                        )}
                        

                        

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                {/* <Dropdown name="weaponType" inputId="weaponType" id="weaponType" value={huntingQuestions?.weaponType} options={trackerOption} onChange={handleChange} optionLabel="label" /> */}
                                <MultiSelect value={huntingQuestions?.typeofWeapon} onChange={handleChange} options={weaponTypeOption} optionLabel="label" name="typeofWeapon" inputId="typeofWeapon" id="typeofWeapon" display="chip"/>
                                <label htmlFor="weaponType">Weapons Type</label>
                            </span>
                            <div className="text-danger">{err.typeofWeapon}</div>
                        </div>

                    </div>
                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Hunting Question
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateHuntingQuestions;
