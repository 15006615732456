import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../../assets/demo/flags/profile.png";
import { Chips } from "primereact/chips";

const ViewComment = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [reportCommentInfo, setReportCommentInfo] = useState({
        reportTitle: "",
        reportComment: "",
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSingleReportComment();
    }, []);

    const getSingleReportComment = () => {
        getData(Constants.END_POINT.VIEW_COMMENT + id)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        reportTitle: res.data.reportTitle,
                        reportComment: res.data.commentId.comment,
                    };
                    setReportCommentInfo(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    {loading ? (
                        <div className="overlay-loading">
                            <div class="spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null}
                    <div className=" flex justify-content-center">
                        <h3>View Comment Report</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid">
                      
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="reportTitle" name="reportTitle" value={reportCommentInfo.reportTitle ? reportCommentInfo.reportTitle : '  '} disabled={true}/>
                                <label htmlFor="reportTitle">Title</label>
                            </span>
                            {/* <div className="text-danger">{err.reportTitle}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="reportComment" name="reportComment" value={reportCommentInfo.reportComment ? reportCommentInfo.reportComment : '  '} disabled={true}/>
                                <label htmlFor="reportComment">Comment</label>
                            </span>
                            {/* <div className="text-danger">{err.reportTitle}</div> */}
                        </div>
                    </div>

                    {/* <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Body Part
                            </Button>
                        }
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ViewComment;
