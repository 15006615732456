import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import profile from "../../assets/demo/flags/profile.png";
import weapon_dummy from "../../assets/demo/flags/weapon_dummy.svg";
import { Chips } from "primereact/chips";

const CreateWeapon = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [weaponImg, setWeaponImg] = useState(null);

    const [weaponQuestions, setWeaponQuestions] = useState([]);
    const [weaponOptionIndex, setWeaponOptionIndex] = useState([]);

    const questionOption = [
        { label: "Text", value: "TEXT" },
        { label: "Dropdown", value: "DROPDOWN" },
    ];

    const weaponOptions = [
        { label: "Crossbow", value: "CROSSBOW" },
        { label: "Compound Bow", value: "COMPOUNDBOW" },
        { label: "Recurve Bow", value: "RECURVE" },
        { label: "Rifle", value: "RIFLE" },
        { label: "Shotgun", value: "SHOTGUN" },
        { label: "Muzzleloader", value: "MUZZLELOADER" },
        { label: "Handgun", value: "HANDGUN" },
    ];

    const [weaponInfo, setWeaponInfo] = useState({
        weaponName: "",
        typeofWeapon: "",
    });

    const [err, setErr] = useState({});

    const handleChangeQuestion = (e) => {
        const { name, value, id } = e.target;
        setWeaponInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        setErr({ ...err, [name]: "" });
    };

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        if (name === "questionType") {
            if (value === "DROPDOWN") {
                let initialIndex = [];
                initialIndex.push(id);
                setWeaponOptionIndex((prev) => {
                    return [...prev, ...initialIndex];
                });

                let test = [];
                test = weaponQuestions.map((item,index) => {
                    if (index == id) {
                        return {
                            ...item,
                            [name]: value,
                        };
                    } else {
                        return item;
                    }
                });
                setWeaponQuestions(test);
            } else {
                let filterIndex = weaponOptionIndex.filter((item) => {
                    return item != id;
                });
                setWeaponOptionIndex(filterIndex);
                let testOption = [];
                testOption = weaponQuestions.map((item,index) => {
                    if (index == id) {
                        return {
                            ...item,
                            [name]: value,
                            option: [],
                        };
                    } else {
                        return item;
                    }
                });
                setWeaponQuestions(testOption);
            }
        } else {
            let test = [];

            test = weaponQuestions.map((item,index) => {
                if (index == id) {
                    return {
                        ...item,
                        [name]: value,
                    };
                } else {
                    return item;
                }
            });
            setWeaponQuestions(test);
        }

        setErr({ ...err, [name]: "" });
    };

    console.log("err", err);

    const validateForm = () => {
        let errors = {};
        if (!weaponInfo.weaponName) {
            errors.weaponName = "Weapon Name is required";
        }
        if (!weaponInfo.typeofWeapon) {
            errors.typeofWeapon = "Weapon type is required";
        }
        if (!weaponImg) {
            errors.weaponImage = "Weapon image is required";
        }

        // if (!productData.questionTitle) {
        //     errors.questionTitle = "Question title is required";
        // }
        // if (!productData.placeholder) {
        //     errors.placeholder = "Placeholder is required";
        // }
        // if (!productData.questionType) {
        //     errors.questionType = "Question type is required";
        // }
        // if (enableoption == true) {
        //     if (!productData.option) {
        //         errors.option = "option is required";
        //     } else {
        //         errors.option = "";
        //     }
        // }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    console.log(err);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("weaponImage", weaponImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                .then((res) => {
                    if (res.success) {
                        let payload = {
                            weaponName: weaponInfo.weaponName,
                            weaponImage: res?.data?.weaponImage,
                            typeofWeapon: weaponInfo.typeofWeapon,
                            questionInfo: weaponQuestions,
                        };
                        postData(Constants.END_POINT.CREATE_WEAPON, payload)
                            .then((res) => {
                                if (res.success) {
                                    console.log("res", res);
                                    toast.current.show({ severity: "info", summary: "", detail: "Weapon has been Created Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/weapons"), 1000)
                                    ;
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const addQuestionField = () => {
        let question = {
            id: weaponQuestions.length,
            questionTitle: "",
            placeholder: "",
            questionType: "",
            option: [],
        };
        setWeaponQuestions((prev) => {
            return [...prev, question];
        });
    };

    const deleteQuestion = (e) => {
        const { id } = e.target;
        let filterweaponQuestion = weaponQuestions.filter((item,index) => {
            return index != id;
        });

        setWeaponQuestions(filterweaponQuestion);
    };

    console.log("weaponImage",weaponImg)

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Weapon</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={weaponImg ? URL.createObjectURL(weaponImg) : weapon_dummy} alt="" className="weaponImageTag" />
                                    {weaponImg!==null ?
                                        <div className="weapon-edit-div">
                                        <i onClick={() => setWeaponImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    :
                                    null
                                    
                                    }
                                    
                                </div>

                                <input
                                    type="file"
                                    id="weaponImag"
                                    name="weaponImage"
                                    onChange={(e) => {
                                        console.log("e.target.files",e.target.files[0])
                                        setWeaponImg(e.target.files[0]);
                                        setErr({ ...err, weaponImage: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="weaponImag" className="weaponImageLabel">
                                    Add Weapon Image
                                </label>
                                <div className="text-danger">{err.weaponImage}</div>
                            </div>
                            {/* <div className="text-danger">{err.weaponName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="weaponName" name="weaponName" onChange={handleChangeQuestion} value={weaponInfo.weaponName} />
                                <label htmlFor="weaponName">Weapon Name</label>
                            </span>
                            <div className="text-danger">{err.weaponName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <Dropdown name="typeofWeapon" inputId="typeofWeapon" value={weaponInfo.typeofWeapon} options={weaponOptions} onChange={handleChangeQuestion} optionLabel="label" />
                                <label htmlFor="typeofWeapon">Type of Weapon</label>
                            </span>
                            <div className="text-danger">{err.typeofWeapon}</div>
                        </div>
                        <div className="field col-12 md:col-12">
                            <label>Question Information</label>
                        </div>
                    </div>
                    <Button className="mb-4" label="Add questions" severity="success" onClick={addQuestionField} />
                    {weaponQuestions.length > 0 &&
                        weaponQuestions.map((item,index) => {
                            return (
                                <div key={index} className="p-fluid grid question-div-wrapper">
                                    <div className="field col-12 md:col-6">
                                        <span className="p-float-label">
                                            <InputText id={index} name="questionTitle" onChange={handleChange} value={item.questionTitle} />
                                            <label htmlFor={index}>Question Title</label>
                                        </span>
                                        <div className="text-danger">{err.questionTitle}</div>
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span className="p-float-label">
                                            <InputText id={index} name="placeholder" onChange={handleChange} value={item.placeholder} />
                                            <label htmlFor={index}>Placeholder</label>
                                            <div className="text-danger">{err.placeholder}</div>
                                        </span>
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <span className="p-float-label">
                                            <Dropdown name="questionType" inputId={index} id={index} value={item?.questionType} options={questionOption} onChange={handleChange} optionLabel="label" />
                                            <label htmlFor={index}>Field Type</label>
                                        </span>
                                        <div className="text-danger">{err.questionType}</div>
                                    </div>
                                    {weaponOptionIndex.indexOf(index) != -1 && (
                                        <div className="field col-12 md:col-6">
                                            <span className="p-float-label">
                                                {/* <Dropdown name="option" inputId={index} id={index} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                                <Chips inputId={index} id={index} placeholder="Please press enter to add" name="option" value={item?.option} onChange={handleChange} separator="," />
                                                {/* <label htmlFor={index}>option</label> */}
                                                <div className="text-danger">{err.option}</div>
                                            </span>
                                        </div>
                                    )}
                                    <div className="cancel-question">
                                        <i className="pi pi-times" style={{ color: "#fe5100", fontSize: "1.2rem", cursor: "pointer" }} id={index} onClick={deleteQuestion}></i>
                                    </div>
                                </div>
                            );
                        })}

                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Weapon
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateWeapon;
