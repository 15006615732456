import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const TrackingQuestionEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();

    const [err, setErr] = useState({});
    const [trackingQuestions, setTrackingQuestions] = useState({
        questionTitle: "",
        questionType: "",
        option: "",
    });
    const [questionOptionIndex, setQuestionOptionIndex] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "questionType") {
            if (value === "DROPDOWN" || value === "LISTVIEW") {
                setQuestionOptionIndex(true);
            } else {
                setQuestionOptionIndex(false);
                setTrackingQuestions((prev) => {
                    return {
                        ...prev,
                        option: "",
                    };
                });
            }
        }

        setTrackingQuestions((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });

        setErr({ ...err, [name]: "" });
    };

    const trackerOption = [
        { label: "Text", value: "TEXT" },
        { label: "Drop Down", value: "DROPDOWN" },
        { label: "Multi Image", value: "MULTIIMAGE" },
        { label: "Single Image", value: "SINGLEIMAGE" },
    ];

    const validateForm = () => {
        let errors = {};
        if (!trackingQuestions.questionTitle) {
            errors.questionTitle = "Question Title is required";
        }
        if (!trackingQuestions.questionType) {
            errors.questionType = "Question Type is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            putData(Constants.END_POINT.UPDATE_TRACKING_QUESTION + id, trackingQuestions)
                .then((res) => {
                    if (res.success) {
                        console.log("res", res);
                        toast.current.show({ severity: "info", summary: "", detail: "Tracking Question has been Updated Succesfully", life: 3000 });
                        setTimeout(() => history.push("/tracking-questions"), 1000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        getSingleQuestion();
    }, []);

    const getSingleQuestion = () => {
        getData(Constants.END_POINT.EDIT_TRACKING_QUESTIONS + id)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        questionTitle: res?.data?.questionTitle,
                        questionType: res?.data?.questionType,
                        option: res?.data?.option,
                    };
                    if(res.data.questionType=="DROPDOWN"){
                        setQuestionOptionIndex(true)
                    }
                    setTrackingQuestions(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="grid" >
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    
                    <div className=" flex justify-content-center">
                        <h3>Edit Tracking Questions</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid question-div-wrapper mt-4">
                        <div className="field col-12 md:col-6" style={trackingQuestions.questionTitle==="Deer Status?" || trackingQuestions.questionTitle==="Have you Found the Deer?" ? {pointerEvents:"none"} : {}}>
                            <span className="p-float-label">
                                <InputText id="questionTitle" name="questionTitle" onChange={handleChange} value={trackingQuestions.questionTitle} />
                                <label htmlFor="questionTitle">Question Title</label>
                            </span>
                            <div className="text-danger">{err.questionTitle}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Dropdown name="questionType" inputId="questionType" id="questionType" value={trackingQuestions?.questionType} options={trackerOption} onChange={handleChange} optionLabel="label" />
                                <label htmlFor="questionType">Field Type</label>
                            </span>
                            <div className="text-danger">{err.questionType}</div>
                        </div>
                        {questionOptionIndex && (
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    {/* <Dropdown name="option" inputId={item.id} id={item.id} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                    <Chips inputId="option" id="option" name="option" placeholder="Please press enter to add" value={trackingQuestions?.option} onChange={handleChange} separator="," />
                                    {/* <label htmlFor="option">option</label> */}
                                    <div className="text-danger">{err.option}</div>
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Tracking Question
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingQuestionEdit;
