import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constant";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import { InputTextarea } from "primereact/inputtextarea";
import Cross from "../../assets/demo/flags/cross.png";
import moment from "moment/moment";
import { Image } from "primereact/image";
import profile from "../../assets/demo/flags/profile.png";
import { Chips } from "primereact/chips";
import body_part_dummy from "../../assets/demo/flags/body_part_dummy.svg";

const BodyPartEdit = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const history = useHistory();


 
   const [bodyPartImg,setBodyPartImg] = useState(null)
    const [bodyPartInfo, setBodyPartInfo] = useState({
        name:"",
        image:""
    });

    const [err, setErr] = useState({});
    const [loading, setLoading] = useState(true);


   

    const validateForm = () => {
        let errors = {};
        if (!bodyPartInfo.name) {
            errors.name = "Name is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    
const handleChange = (e) => {
const {name,value} = e.target;
setBodyPartInfo((prev)=>{
    return{
        ...prev,
        [name] : value
    }
})
setErr({ ...err, name: "" })
    // (e)=>{setBodyPartInfo(e.target.value);setErr({ ...err, name: "" });}
}

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            if(bodyPartImg!=null){
                const formData = new FormData();
            formData.append("weaponImage", bodyPartImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                .then((res) => {
                    if (res.success) {
                        let payload = {
                            image:res?.data?.weaponImage,
                            name:bodyPartInfo.name
                        }
                        putData(Constants.END_POINT.UPDATE_BODY_PART+id, payload)
                        .then((res) => {
                            if (res.success) {
                                toast.current.show({ severity: "info", summary: "", detail: "Body Part has been Updated Succesfully", life: 3000 });
                                            setTimeout(()=>history.push("/body-part"), 1000)
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
            else{
                let payload = {
                    image:bodyPartInfo.image,
                    name:bodyPartInfo.name
                }
                putData(Constants.END_POINT.UPDATE_BODY_PART+id, payload)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "Body Part has been Updated Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/body-part"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        getSingleBodyPart();
    }, []);

    const getSingleBodyPart = () => {
        getData(Constants.END_POINT.EDIT_BODY_PART + id)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        name:res.data.name,
                        image:res.data.image
                    }
                    setBodyPartInfo(obj)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
                    <div className=" flex justify-content-center">
                        <h3>Edit Body Part</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={bodyPartImg ? URL.createObjectURL(bodyPartImg) : bodyPartInfo.image!=="" ? Constants.BASE_URL + bodyPartInfo.image  : body_part_dummy} alt="" className="weaponImageTag" />
                                    {bodyPartImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setBodyPartImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    onChange={(e) => {
                                        setBodyPartImg(e.target.files[0]);
                                        setErr({ ...err, image: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="image" className="weaponImageLabel">
                                    Edit Body Part Image
                                </label>
                                <div className="text-danger">{err.image}</div>
                            </div>
                            {/* <div className="text-danger">{err.name}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="name" name="name" onChange={handleChange} value={bodyPartInfo.name} />
                                <label htmlFor="name">Name</label>
                            </span>
                            <div className="text-danger">{err.name}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Body Part
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BodyPartEdit;
