import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { deleteData, getData } from "../../services/http.service";
import Constants from "../../services/constant";
// import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Rating } from "primereact/rating";
import { formatDate } from "../../utils/dateConversions";
import dummy from "../../assets/images/dummy1.png"
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
const StateLaws = () => {
    const toast = useRef(null);
    useEffect(() => {
        getAllStateLaws();
    }, []);
    const getAllStateLaws = () => {
        getData(Constants.END_POINT.GET_ALL_STATE_LAWS)
            .then((res) => {
                if (res.success) {
                    setStateLaws(res.data);
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deleteStateLaws = (id) => {
        deleteData(Constants.END_POINT.DELETE_STATE_LAWS+id)
        .then((res)=>{
            if(res.success){
                toast.current.show({ severity: "info", summary: "", detail: "State Law Deleted Succesfully", life: 3000 });
                getAllStateLaws();
            }
        })
    }

    const [species, setStateLaws] = useState();
    const [loading, setLoading] = useState(true);


    const acceptFunc = (id) => {
        deleteStateLaws(id)
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const deleteConfirm = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName:'cancel-button',
            accept:()=>acceptFunc(id),
            reject
        });
    };

    const ActionBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Link to={`/state-laws-edit/${rowData?._id}`}>
                    <Button type="button" className="p-button-info mx-2">
                        Edit
                    </Button>
                </Link>

                <Button
                    type="button"
                    className="p-button-danger "
                    onClick={()=>deleteConfirm(rowData?._id)}
                >
                    Delete
                </Button>   
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
            <div className="flex justify-content-between mb-4">
            <Toast ref={toast} />
                        <h5>State Laws</h5>
                        <div className="flex justify-content-end mr-3">
                            <Link to="/create-state-laws">
                                <Button className="p-button-info" icon="pi pi-plus" label="Create State Law" />
                            </Link>
                        </div>
                    </div>
                <DataTable value={species} paginator rows={10} loading={loading}>
                    <Column field="state"  header="State" sortable  />
                    <Column field="url" header="URL" sortable  />
                    <Column field="block" header="Action" body={ActionBodyTemplate} style={{width:'10px'}} />
                </DataTable>
            </div>
        </div>
    );
};

export default StateLaws;
