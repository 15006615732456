import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import target_dummy from "../../assets/demo/flags/target-dummy.svg";
import { Chips } from "primereact/chips";

const CreateTargetPoint = () => {
    const toast = useRef(null);
    const history = useHistory();



 
   const [targetPointImg,setTargetPointImg] = useState(null)
    const [targetPointInfo, setTargetPointInfo] = useState({
        pointName:"",
        minTime:"",
        maxTime:"",
    });

    const [err, setErr] = useState({});

const handleChangeTarget = (e) => {
    const {name,value} = e.target
    setTargetPointInfo((prev)=>{
        return {
            ...prev,
            [name]:value,
        }
    })
        setErr({ ...err, [name]: "" });
}

    console.log("err", err);

    const validateForm = () => {
        let errors = {};
        if (!targetPointInfo.pointName) {
            errors.pointName = "Target Point Name is required";
        }
        if (!targetPointInfo.minTime) {
            errors.minTime = "minimum Time is required";
        }
        if (!targetPointInfo.maxTime) {
            errors.maxTime = "maximum Time is required";
        }
        if (!targetPointImg) {
            errors.image = "Target Point Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("weaponImage", targetPointImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                .then((res) => {
                    if (res.success) {
                        let payload = {
                            image:res?.data?.weaponImage,
                            pointName:targetPointInfo.pointName,
                            minTime:targetPointInfo.minTime,
                            maxTime:targetPointInfo.maxTime,
                        }
                        postData(Constants.END_POINT.CREATE_TARGET_POINT, payload)
                        .then((res) => {
                            if (res.success) {
                                toast.current.show({ severity: "info", summary: "", detail: "Target Point has been Created Succesfully", life: 3000 });
                                            setTimeout(()=>history.push("/target-point"), 1000)
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };




    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Target Point</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={targetPointImg ? URL.createObjectURL(targetPointImg) : target_dummy} alt="" className="weaponImageTag" />
                                    {targetPointImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setTargetPointImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="targetPointImg"
                                    name="targetPointImg"
                                    onChange={(e) => {
                                        setTargetPointImg(e.target.files[0]);
                                        setErr({ ...err, image: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="targetPointImg" className="weaponImageLabel">
                                    Add Target Point Image
                                </label>
                                <div className="text-danger">{err.image}</div>
                            </div>
                            {/* <div className="text-danger">{err.pointName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="pointName" name="pointName" onChange={handleChangeTarget} value={targetPointInfo.pointName} />
                                <label htmlFor="pointName">Target Point Name</label>
                            </span>
                            <div className="text-danger">{err.pointName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="minTime" name="minTime" onChange={handleChangeTarget} value={targetPointInfo.minTime} />
                                <label htmlFor="minTime">Minimum time</label>
                            </span>
                            <div className="text-danger">{err.minTime}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="maxTime" name="maxTime" onChange={handleChangeTarget} value={targetPointInfo.maxTime} />
                                <label htmlFor="maxTime">Maximum time</label>
                            </span>
                            <div className="text-danger">{err.maxTime}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Target Point
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTargetPoint;
