import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { deleteData, getData } from "../services/http.service";
import Constants from "../services/constant";
import profile from "../assets/demo/flags/profile.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

const Users = () => {
    useEffect(() => {
        getAllUsers();
    }, []);
    const getAllUsers = () => {
        getData(Constants.END_POINT.USERS)
            .then((res) => {
                if (res.success) {
                    setUsers(res.data);
                    setLoading(false)
                    initFilters();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const blockUser = (id) => {
        getData(Constants.END_POINT.BLOCK_UNBLOCK_USER + id)
            .then((res) => {
                getAllUsers();
            })
            .catch((err) => console.log(err));
    };
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    // console.log("user",users)

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image ? Constants.BASE_URL + rowData.image : profile} alt={rowData.image} width={50} />;
    };
    const statusBodyTemplate2 = (rowData) => {
        return <span className={`product-badge status-${rowData.isOnline ? "instock" : "outofstock"}`}>{rowData.isOnline ? "Online" : "Offline"}</span>;
    };

    const [filters, setFilters] = useState(null);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        });
        setGlobalFilterValue('');
    };


    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    
    const acceptFunc = (id) => {
        deleteData(Constants.END_POINT.DELETE_USER + id)
        .then((res) => {
            getAllUsers();
        })
        .catch((err) => console.log(err));
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const deleteConfirm = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName:'cancel-button',
            accept:()=>acceptFunc(id),
            reject
        });
    };
    const ActionBodyTemplate = (rowData) => {
        return (
            <div className=" flex ">
                <Link to={`/user-view/${rowData?._id}`}>
                    <Button type="button" className="p-buuton-raised p-button-rounded p-button-detail mx-2">
                        View
                    </Button>
                </Link>
                {/* <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined ${rowData?.isDeleted ? "p-button-success" : "p-button-danger"}`} onClick={() => blockUser(rowData?._id)}>
                    {rowData?.isDeleted ? "Unblock" : "Block"}
                </Button> */}
                <Button type="button" className={`p-button-raised p-button-rounded  p-button-outlined p-button-danger`} onClick={()=>deleteConfirm(rowData?._id)}>
                    Delete
                </Button>
            </div>
        );
    };

    const mobileTemplate = (rowData) => {
        return (
            <div>
                {rowData.mobile ? rowData.mobile : "Not Found"}
            </div>
        )
    }

    const nameTemplate = (rowData) => {
        return (
            <div>
                {rowData.firstName || rowData.lastName ? rowData.firstName+" "+rowData.lastName : "Not Found"}
            </div>
        )
    }

    const emailTemplate = (rowData) => {
        return (
            <div>
                {rowData.email ? rowData.email : "Not Found"}
            </div>
        )
    }

    const rolTemplate = (rowData) => {
        return (
            <div>
                {rowData.role ? rowData.role : "Not Found"}
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search User" />
                </span>
            </div>
        );
    };

    const header = renderHeader()

    return (
        <div className="col-12">
            <div className="card">
                <h5>Users</h5>
                <DataTable
                header={header}
                    value={users}
                    paginator
                    rows={10}
                    loading={loading}
                    filters={filters}
                    globalFilterFields={['firstName', 'email', 'mobile', 'role','isOnline']}
                    // expandedRows={expandedRows}
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    // responsiveLayout="scroll"
                    // rowExpansionTemplate={rowExpansionTemplate}
                    // dataKey="id"
                    // header={header}
                >
                    {/* <Column expander style={{ width: "3em" }} /> */}
                    <Column field="firstName"  header="Name" body={nameTemplate} sortable />
                    <Column field="email" header="Email" sortable body={emailTemplate}/>
                    <Column field="mobile" header="Mobile" body={mobileTemplate} sortable />
                    <Column field="role" header="Role" sortable body={rolTemplate} />
                    <Column header="Image" body={imageBodyTemplate} />
                    <Column field="isOnline" header="Status" sortable body={statusBodyTemplate2} />
                    <Column field="block" header="Action" body={ActionBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default Users;
