import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1, putData, putData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import { Chips } from "primereact/chips";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const TrackerQuestionEdit = () => {
    const toast = useRef(null);
    const history = useHistory();
    const {id}  = useParams()

    const [err, setErr] = useState({});
    const [trackerQuestions, setTrackerQuestions] = useState({
        questionTitle: "",
        placeholder: "",
        questionType: "",
        option: [],
        optionType:"",
        listOption:[],
    });
    const [questionOptionIndex, setQuestionOptionIndex] = useState(false);
    const [dropdownList,setDropdownList] = useState(false)
    const [dropdownListOption,setDropdownListOption] = useState([])
    const [loading, setLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name==="questionType"){
            if(value==="DROPDOWN"){
                setQuestionOptionIndex(true)
                setDropdownList(false)
            }
            else if(value==="DROPDOWNLIST"){
                setDropdownList(true)
                setQuestionOptionIndex(false)
            }
            else{
                setQuestionOptionIndex(false)
                setDropdownList(false)
                setTrackerQuestions((prev)=>{
                    return {
                        ...prev,
                        option:[],
                        optionType:'',
                        listOption:[],
                    }
                })
            }
        }
          
            setTrackerQuestions((prev)=>{
                return {
                    ...prev,
                    [name]:value
                }
            })
            
        setErr({ ...err, [name]: "" });
    };

    const trackerOption = [
        { label: "Text", value: "TEXT" },
        { label: "Drop Down", value: "DROPDOWN" },
        { label: "Drop Down List", value: "DROPDOWNLIST" },
    ];

    const optionType = [
        {label: "Checkbox",value:"CHECKBOX"},
        {label: "List",value:"LIST"},
    ]

    const validateForm = () => {
        let errors = {};
        if (!trackerQuestions.questionTitle) {
            errors.questionTitle = "Question Title is required";
        }
        if (!trackerQuestions.placeholder) {
            errors.placeholder = "placeholder is required";
        }
        if (!trackerQuestions.questionType) {
            errors.questionType = "Question Type is required";
        }


        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const addListOptionFunc = () => {
        let array = [...dropdownListOption]
        let obj = {
            experience: "",
            color: ""
        }
        array.push(obj)
        setDropdownListOption(array)
    }

    const ListhandleChange = (e,i) => {
        const {name,value} = e.target;
        let array = [...dropdownListOption]
        let obj = {
            ...dropdownListOption[i],
            [name]:value
        }
        array.splice(i,1,obj)
        setDropdownListOption(array)
    }

    const deleteQuestionListOption = (i) => {
        let array= [...dropdownListOption];
        array.splice(i,1)
        setDropdownListOption(array)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            let payload;
            if(trackerQuestions.questionType==="DROPDOWNLIST"){
                payload = {
                    questionTitle: trackerQuestions.questionTitle,
        placeholder: trackerQuestions.placeholder,
        questionType: trackerQuestions.questionType,
        listOption:dropdownListOption,
                }
            }
            else if(trackerQuestions.questionType==="DROPDOWN"){
                payload = {
                    questionTitle: trackerQuestions.questionTitle,
                    placeholder: trackerQuestions.placeholder,
                    questionType: trackerQuestions.questionType,
                    option: trackerQuestions.option,
                    optionType:trackerQuestions.optionType,
                }
            }
            else{
                payload = {
                    questionTitle: trackerQuestions.questionTitle,
                    placeholder: trackerQuestions.placeholder,
                    questionType: trackerQuestions.questionType,
                }
            }
            putData(Constants.END_POINT.UPDATE_TRACKER_QUESTION + id, payload)
            .then((res) => {
                if (res.success) {
                    console.log("res", res);
                    toast.current.show({ severity: "info", summary: "", detail: "Tracker Question has been Created Succesfully", life: 3000 });
                    setTimeout(()=>history.push("/tracker-questions"), 1000)
                    ;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        getSingleQuestion();
    }, []);

    const getSingleQuestion = () => {
        getData(Constants.END_POINT.EDIT_TRACKER_QUESTION + id)
            .then((res) => {
                if (res.success) {
                    let obj = {
                        questionTitle: res?.data?.questionTitle,
                        questionType: res?.data?.questionType,
                        placeholder:res?.data?.placeholder,
                        option: res?.data?.option,
                        optionType:res?.data?.optionType,
                        listOption:[],
                    };
                    if(res.data.questionType=="DROPDOWN"){
                        setQuestionOptionIndex(true)
                    }
                    if(res.data.questionType=="DROPDOWNLIST"){
                        setDropdownList(true)
                        setDropdownListOption(res?.data?.listOption)
                    }
                    setTrackerQuestions(obj);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


  return (
    <div className="grid" >
    <div className="col-12 md:col-12 mx-auto">
        <Toast ref={toast} />
        <div className="card ">
        {loading ? 
                    <div className="overlay-loading">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : null}
            <div className=" flex justify-content-center">
                <h3>Edit Tracker Questions</h3>
                <div className="ml-4 align-right"></div>
            </div>
            <div className="p-fluid grid question-div-wrapper mt-4">
                <div className="field col-12 md:col-6" style={trackerQuestions.questionTitle==="Enter Dog Type" || trackerQuestions.questionTitle==="How many Year’s of experience" || trackerQuestions.questionTitle==="Do you have any certifications" ? {pointerEvents:"none"} : {}}>
                    <span className="p-float-label">
                        <InputText id="questionTitle" name="questionTitle" onChange={handleChange} value={trackerQuestions.questionTitle} />
                        <label htmlFor="questionTitle">Question Title</label>
                    </span>
                    <div className="text-danger">{err.questionTitle}</div>
                </div>
                <div className="field col-12 md:col-6" style={trackerQuestions.questionTitle==="Enter Dog Type" || trackerQuestions.questionTitle==="How many Year’s of experience" || trackerQuestions.questionTitle==="Do you have any certifications" ? {pointerEvents:"none"} : {}}>
                    <span className="p-float-label">
                        <InputText id="placeholder" name="placeholder" onChange={handleChange} value={trackerQuestions.placeholder} />
                        <label htmlFor="placeholder">Placeholder</label>
                        <div className="text-danger">{err.placeholder}</div>
                    </span>
                </div>
                <div className="field col-12 md:col-6">
                    <span className="p-float-label">
                        <Dropdown name="questionType" inputId="questionType" id="questionType" value={trackerQuestions?.questionType} options={trackerOption} onChange={handleChange} optionLabel="label" />
                        <label htmlFor="questionType">Field Type</label>
                    </span>
                    <div className="text-danger">{err.questionType}</div>
                </div>
                {questionOptionIndex && (
                            <>
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    {/* <Dropdown name="option" inputId={item.id} id={item.id} value={item?.option} options={yearOptions} onChange={handleChange} optionLabel="label" /> */}
                                    <Chips inputId="option" id="option" name="option" placeholder="Please press enter to add" value={trackerQuestions?.option} onChange={handleChange} separator="," />
                                    {/* <label htmlFor="option">option</label> */}
                                    <div className="text-danger">{err.option}</div>
                                </span>
                            </div>

<div className="field col-12 md:col-6">
<span className="p-float-label">
    <Dropdown name="optionType" inputId="optionType" id="optionType" value={trackerQuestions?.optionType} options={optionType} onChange={handleChange} optionLabel="label" />
    <label htmlFor="optionType">Option Type</label>
</span>
<div className="text-danger">{err.optionType}</div>
</div>
</>
                        )}



                        {dropdownList && <div className="col-12 md:col-12">
                           { dropdownListOption.map((item,index)=>{
                                return(
                                <div className="p-fluid grid question-div-wrapper" style={{marginTop:'1px',}}>
                                <div className="field col-12 md:col-6">
                                <span className="p-float-label">
                                    <InputText id="experience" name="experience" onChange={(e)=>ListhandleChange(e,index)} value={item.experience} />
                                    <label htmlFor="experience">Experience</label>
                                </span>
                                <div className="text-danger">{err.experience}</div>
                            </div>
                             <div className="field col-12 md:col-6">
                             <span className="p-float-label">
                                 <InputText id="color" name="color" onChange={(e)=>ListhandleChange(e,index)} value={item.color} />
                                 <label htmlFor="color">Color</label>
                             </span>
                             <div className="text-danger">{err.color}</div>
                         </div>
                         <div className="cancel-question">
                                        <i className="pi pi-times" style={{ color: "#fe5100", fontSize: "1.2rem", cursor: "pointer" }} id={index} onClick={()=>deleteQuestionListOption(index)}></i>
                                    </div>
                         </div>
                                )
                            })


                        }

                        <Button style={{maxWidth:'max-content'}} type="button" className="p-button-md p-button-success" onClick={addListOptionFunc}>
                                Add List Option
                            </Button>
</div>
                        }



                    </div>
                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Update Tracker Question
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TrackerQuestionEdit