import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import species_dummy from "../../assets/demo/flags/species_dummy.svg";
import { Chips } from "primereact/chips";

const CreateSpecies = () => {
    const toast = useRef(null);
    const history = useHistory();



 
   const [speciesImg,setSpeciesImg] = useState(null)
    const [speciesInfo, setSpeciesInfo] = useState("");

    const [err, setErr] = useState({});



    console.log("err", err);

    const validateForm = () => {
        let errors = {};
        if (!speciesInfo) {
            errors.speciesName = "Species Name is required";
        }
        if (!speciesImg) {
            errors.speciesImage = "species Image is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("speciesImage", speciesImg);
            formData.append("speciesName", speciesInfo);
            postData1(Constants.END_POINT.CREATE_SPECIES, formData)
                .then((res) => {
                    if (res.success) {
                        toast.current.show({ severity: "info", summary: "", detail: "Species has been Created Succesfully", life: 3000 });
                                    setTimeout(()=>history.push("/species"), 1000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };




    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Species</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    {/* <img src={product} alt="" width="10%" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} /> */}
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={speciesImg ? URL.createObjectURL(speciesImg) : species_dummy} alt="" className="weaponImageTag" />
                                    {speciesImg!==null ?
                                    <div className="weapon-edit-div">
                                        <i onClick={()=>setSpeciesImg(null)}  className="fa-sharp fa-solid fa-trash delete"></i>
                                    </div>
                                    : null }
                                </div>

                                <input
                                    type="file"
                                    id="speciesImage"
                                    name="speciesImage"
                                    onChange={(e) => {
                                        setSpeciesImg(e.target.files[0]);
                                        setErr({ ...err, speciesImage: "" });
                                    }}
                                    onClick={(event)=> { 
                                        event.currentTarget.value = null
                                   }}
                                    hidden
                                />
                                <label htmlFor="speciesImage" className="weaponImageLabel">
                                    Add Species Image
                                </label>
                                <div className="text-danger">{err.speciesImage}</div>
                            </div>
                            {/* <div className="text-danger">{err.speciesName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="speciesName" name="speciesName" onChange={(e)=>{setSpeciesInfo(e.target.value);setErr({ ...err, speciesName: "" });}} value={speciesInfo} />
                                <label htmlFor="speciesName">Species Name</label>
                            </span>
                            <div className="text-danger">{err.speciesName}</div>
                        </div>
                        
                       
                    </div>
         

                    <div className="flex justify-content-left mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Species
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateSpecies;
